import React from "react"
import TicketCardPage from "../../pages/Admin/components/Events/TicketCardPage"

const Home = React.lazy(() => import("../../pages/Home"))
const ActualEvent = React.lazy(() => import("../../pages/ActualEvent"))
const Blog = React.lazy(() => import("../../pages/Blog/Blog"))
const Blog2 = React.lazy(() => import("../../pages/Blog/Blog2"))
const Blog3 = React.lazy(() => import("../../pages/Blog/Blog3"))
const Book = React.lazy(() => import("../../pages/Book"))
const Loyalty = React.lazy(() => import("../../pages/Loyalty/Loyalty"))
const LoyaltyBasic = React.lazy(() => import("../../pages/Loyalty/LoyaltyBasic"))
const LoyaltyAuth = React.lazy(() => import("../../pages/Loyalty/LoyaltyAuth"))
const LoyaltyStore = React.lazy(() => import("../../pages/Loyalty/LoyaltyStore"))
const LoyaltyStoreProduct = React.lazy(() => import("../../pages/Loyalty/LoyaltyStoreProduct"))
const LoyaltyStoreProduct2 = React.lazy(() => import("../../pages/Loyalty/LoyaltyStoreProduct2"))
const LoyaltyOrders = React.lazy(() => import("../../pages/Loyalty/LoyaltyOrders"))
const LoyaltyAccount = React.lazy(() => import("../../pages/Loyalty/Account/LoyaltyAccount"))
const Vacancies = React.lazy(() => import("../../pages/Vacancies"))
const Franchise = React.lazy(() => import("../../pages/Franchise"))
const Branches = React.lazy(() => import("../../pages/Branches/Branches"))
const BranchPage = React.lazy(() => import("../../pages/Branches/BranchPage"))
const Privacy = React.lazy(() => import("../../pages/Privacy"))
const NotFoundPage = React.lazy(() => import("../../pages/NotFoundPage"))

const Admin = React.lazy(() => import("../../pages/Admin/Admin"))
const AdminAuth = React.lazy(() => import("../../pages/Admin/AdminAuth"))

const BarberShow2 = React.lazy(() => import("../../pages/Events/BBShow2/BarberShow2"))
const BarberBattles = React.lazy(() => import("../../pages/Events/BBShow2/BarberBattles"))
const BBShowAction = React.lazy(() => import("../../pages/Events/BBShow2/BBShowAction"))

export interface IRoute {
  path: string
  component: React.ReactNode
  exact?: boolean
}

export enum RouteNames {
  P404 = '*',
  HOME = '/',
  BBSHOW = '/barbershow',
  BBSHOW2 = '/barbershow2',
  BBSHOW2_BTLS = '/barbershow/battles',
  BBSHOW2_ACT = '/barbershow/action',
  BLOG = '/blog',
  BLOG_ID = '/blog/:page',
  BLOG_ID_POST = '/blog/:page/:post',
  BLOG2 = '/blog2',
  BLOG3 = '/blog3',
  BOOK = '/book',

  LOYALTY = '/loyalty',
  LOYALTY_HIDDEN = '/loyalty_hidden',
  LOYALTY_AUTH = '/loyalty/auth',
  LOYALTY_STORE = '/loyalty/store',
  LOYALTY_STORE_PROD = '/loyalty/store/:id',
  LOYALTY_STORE_PROD2 = '/loyalty/store/2/:id',
  LOYALTY_ACCOUNT = '/loyalty/account',
  LOYALTY_ACCOUNT_PAGE = '/loyalty/account/:page',
  LOYALTY_ORDERS = '/loyalty/orders',
  
  VACANCIES = '/vacancies',
  VACANCIES2 = '/vacancies2',
  FRANCHISE = '/franchise',
  BRANCHES = '/branches',
  BRANCHE_PAGE = '/branches/:name',
  BRANCHE_PAGE_RG = '/branches/rg/:rid',
  BRANCHE_RG_PAGE = '/branches/:rid/:id',

  B_BBSH = '/barbershop',
  B_BBSHS = '/barbershops',
  B_BBSH_P = '/barbershop/:name',
  B_BBSH_P1 = '/barbershop/rg/:rid',
  B_BBSH_P2 = '/barbershop/:rid/:id',

  B_BRANCHES = '/b',
  B_BRANCHE_PAGE = '/b/:name',
  B_BRANCHE_PAGE_RG = '/b/rg/:rid',
  B_BRANCHE_RG_PAGE = '/b/:rid/:id',

  BEBARBER = '/bebarber',
  PRIVACY = '/privacy',

  ADMIN = '/admin',
  ADMIN_AUTH = '/admin/signin',

  ADMIN_HOME = '/admin/home',
  ADMIN_BRAN = '/admin/branches',
  ADMIN_BRAN_GO = '/admin/branches_go/:go_cid',
  ADMIN_BRAN_C = '/admin/branches/:cid',
  ADMIN_BRAN_C_R = '/admin/branches/:cid/:rid',
  ADMIN_BRAN_C_R_B = '/admin/branches/:cid/:rid/:bid',
  ADMIN_BLOG = '/admin/blog',
  ADMIN_BLOG_POST = '/admin/blog/:id',
  ADMIN_FRAN = '/admin/franchise',
  ADMIN_USERS = '/admin/users',
  ADMIN_USERS_O = '/admin/users/:id',
  ADMIN_CRM = '/admin/crm',
  ADMIN_ACCOUNT = '/admin/account',

  ADMIN_ADS_NETWORK = '/admin/adsnetwork',
  ADMIN_ADS_NETWORK_B = '/admin/adsnetwork/branches',
  ADMIN_ADS_NETWORK_M = '/admin/adsnetwork/mats',
  ADMIN_ADS_NETWORK_B_ID = '/admin/adsnetwork/branches/:id',
  ADMIN_ADS_NETWORK_M_ID = '/admin/adsnetwork/mats/:id',

  ADMIN_LOYALTY = '/admin/loyalty',
  ADMIN_LOYALTY_STATS = '/admin/loyalty/stats',
  ADMIN_LOYALTY_CLIENTS = '/admin/loyalty/clients',
  ADMIN_LOYALTY_CLIENTS_ID = '/admin/loyalty/clients/:id',
  ADMIN_LOYALTY_STORE = '/admin/loyalty/store',
  ADMIN_LOYALTY_STORE_ID = '/admin/loyalty/store/:id',
  ADMIN_LOYALTY_ORDERS = '/admin/loyalty/orders',
  ADMIN_LOYALTY_ORDERS_ID = '/admin/loyalty/orders/:id',
  ADMIN_LOYALTY_PROMOS = '/admin/loyalty/promos',
  ADMIN_LOYALTY_PROMOS_ID = '/admin/loyalty/promos/:id',
  ADMIN_LOYALTY_STORIES = '/admin/loyalty/stories',
  ADMIN_LOYALTY_MAXMA = '/admin/loyalty/maxma',

  ADMIN_WIDGET = '/admin/widget',
  ADMIN_WIDGET_ID = '/admin/widget/:id',

  ADMIN_EVENTS = '/admin/events',
  ADMIN_EVENTS_O = '/admin/events/:id',
  ADMIN_EVENTS_T = '/admin/event_ticket/:ticket_id',
  ADMIN_EVENTS_O_SCAN = '/admin/events/:id/scanner',
  ADMIN_EVENTS_SCANNER = '/admin/events_scanner',
  ADMIN_EVENTS_ACTIONX = '/admin/events_actionx',
  ADMIN_CONTENT = '/admin/content',
  ADMIN_SETT = '/admin/settings'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.P404, exact: true, component: <NotFoundPage /> },
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.BBSHOW, exact: true, component: <BarberShow2 /> },
  { path: RouteNames.BBSHOW2, exact: true, component: <BarberShow2 /> },
  { path: RouteNames.BBSHOW2_BTLS, exact: true, component: <BarberBattles /> },
  { path: RouteNames.BBSHOW2_ACT, exact: true, component: <BBShowAction /> },
  { path: RouteNames.BLOG, exact: true, component: <Blog3 /> },
  { path: RouteNames.BLOG_ID, exact: true, component: <Blog3 /> },
  { path: RouteNames.BLOG_ID_POST, exact: true, component: <Blog3 /> },
  { path: RouteNames.BOOK, exact: true, component: <Book /> },

  { path: RouteNames.LOYALTY, exact: true, component: <LoyaltyBasic /> },
  { path: RouteNames.LOYALTY_HIDDEN, exact: true, component: <Loyalty /> },
  { path: RouteNames.LOYALTY_AUTH, exact: true, component: <LoyaltyAuth /> },
  { path: RouteNames.LOYALTY_ACCOUNT, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_ACCOUNT_PAGE, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_STORE, exact: true, component: <LoyaltyStore /> },
  { path: RouteNames.LOYALTY_STORE_PROD, exact: true, component: <LoyaltyStoreProduct /> },
  // { path: RouteNames.LOYALTY_STORE_PROD2, exact: true, component: <LoyaltyStoreProduct2 /> },
  { path: RouteNames.LOYALTY_ORDERS, exact: true, component: <LoyaltyStore /> },

  { path: RouteNames.VACANCIES, exact: true, component: <Vacancies /> },
  { path: RouteNames.FRANCHISE, exact: true, component: <Franchise /> },

  { path: RouteNames.BRANCHES, exact: true, component: <Branches /> },
  { path: RouteNames.BRANCHE_PAGE, exact: true, component: <BranchPage /> },
  { path: RouteNames.BRANCHE_PAGE_RG, exact: true, component: <BranchPage /> },
  { path: RouteNames.BRANCHE_RG_PAGE, exact: true, component: <BranchPage /> },

  { path: RouteNames.B_BRANCHES, exact: true, component: <Branches /> },
  { path: RouteNames.B_BRANCHE_PAGE, exact: true, component: <BranchPage /> },
  { path: RouteNames.B_BRANCHE_PAGE_RG, exact: true, component: <BranchPage /> },
  { path: RouteNames.B_BRANCHE_RG_PAGE, exact: true, component: <BranchPage /> },

  { path: RouteNames.B_BBSH, exact: true, component: <Branches /> },
  { path: RouteNames.B_BBSHS, exact: true, component: <Branches /> },
  { path: RouteNames.B_BBSH_P, exact: true, component: <BranchPage /> },
  { path: RouteNames.B_BBSH_P1, exact: true, component: <BranchPage /> },
  { path: RouteNames.B_BBSH_P2, exact: true, component: <BranchPage /> },

  { path: RouteNames.PRIVACY, exact: true, component: <Privacy /> },

  { path: RouteNames.ADMIN, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_HOME, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRAN, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRAN_GO, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRAN_C, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRAN_C_R, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRAN_C_R_B, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BLOG, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BLOG_POST, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_FRAN, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CRM, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_USERS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_USERS_O, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ACCOUNT, exact: true, component: <Admin /> },

  { path: RouteNames.ADMIN_ADS_NETWORK, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ADS_NETWORK_B, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ADS_NETWORK_M, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ADS_NETWORK_B_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ADS_NETWORK_M_ID, exact: true, component: <Admin /> },

  { path: RouteNames.ADMIN_LOYALTY, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_STATS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_CLIENTS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_CLIENTS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_STORE, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_STORE_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_ORDERS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_ORDERS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_PROMOS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_PROMOS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_STORIES, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_LOYALTY_MAXMA, exact: true, component: <Admin /> },

  { path: RouteNames.ADMIN_WIDGET, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_WIDGET_ID, exact: true, component: <Admin /> },

  { path: RouteNames.ADMIN_EVENTS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_EVENTS_T, exact: true, component: <TicketCardPage /> },
  { path: RouteNames.ADMIN_EVENTS_O, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_EVENTS_O_SCAN, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_EVENTS_SCANNER, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_EVENTS_ACTIONX, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CONTENT, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SETT, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> }
]

export const twRoutes: IRoute[] = []
