import {observer} from "mobx-react-lite"
import {FC, useContext} from "react"
import {useLocation, useNavigate} from "react-router-dom";
import {Context} from "..";
import {LOCALES} from "../core/i18n/locales";

import { FormattedMessage } from "react-intl"

import styles from "../styles/Footer.module.scss"
import {StorageKeys} from "../core/utils/defaults";

const Footer: FC = () => {

  const { store } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    let url = event.target.pathname || "/";

    if (event.target.tagName.toLowerCase() == "span") {
      url = event.target.parentElement.pathname;
    }

    if (url != location.pathname) {
      console.log(event, url);
  
      store.setIsFaderVisible(true);
      setTimeout(() => {
        store.setIsFaderShown(true);
        setTimeout(() => {
          navigate(url);
        }, 400);
      }, 100);
    }
  }

  const setupLocale = (locale: string) => {
    store.setLocale(locale)
    localStorage.setItem(StorageKeys.LOCALE, locale)
  }
  
  return (
    <>
      <div className={styles.section_footer} id="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className={styles.links}>
                {location.pathname == "/franchise" ? <a href="tel:+79265577997" style={{userSelect: "none", pointerEvents: "none"}}><span>{store.locale == LOCALES.RUSSIAN ? "Телефон основателя Алексея" : "Founder Alexey's phone"}</span></a> : <a href="https://moscowbarberingschool.ru/"><span><FormattedMessage id="header_menu_item6" /></span></a>}
                <a href="/uploads/Рекламное_предложение_TG.pdf" target="_blank"><span>{store.locale == LOCALES.RUSSIAN ? "Рекламное предложение" : "Advertising offer"}</span></a>
                {location.pathname == "/franchise" ? <a href="tel:+79265577997"><span>+7 926 557-79-97</span></a> : <a href="tel:88002004420"><span>8 (800) 200 44 20</span></a>}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className={styles.mobapp}>
                <div><FormattedMessage id="footer_mob_app" /></div>
                <div>
                  <a target="_blank" href="https://apps.apple.com/us/app/topgun-%D0%B1%D0%B0%D1%80%D0%B1%D0%B5%D1%80%D1%88%D0%BE%D0%BF/id1187931491"><img src="/assets/images/appstore.svg" /></a>
                  <a target="_blank" href="https://play.google.com/store/apps/details?id=yclients.apps.salon75489"><img src="/assets/images/googleplay.svg" /></a>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className={styles.links_f}>
                <div><a target="_blank" href="https://www.instagram.com/topgun.ru/"><span className={styles.ig}></span> INSTAGRAM</a></div>
                <div><a target="_blank" href="https://vk.com/video/@topgun"><span className={styles.vkv}></span> VK {store.locale == LOCALES.RUSSIAN ? "ВИДЕО" : "VIDEOS"}</a></div>
                <div><a target="_blank" href="https://t.me/topgun_ru"><span className={styles.tg}></span> TELEGRAM</a></div>
                <div><a target="_blank" href={location.pathname == "/franchise" ? "https://vk.com/lokoncev?from=search" : "https://vk.com/topgun"}><span className={styles.vk}></span> {store.locale == LOCALES.RUSSIAN ? "ВКОНТАКТЕ" : "VKONTAKTE"}</a></div>
                <div><a target="_blank" href={location.pathname == "/franchise" ? "https://dzen.ru/lokoncev42" : "https://dzen.ru/topgunbarbershop?share_to=link"}><span className={styles.dz}></span> {store.locale == LOCALES.RUSSIAN ? "ДЗЕН" : "Zen"}</a></div>
                <div><a target="_blank" href="https://www.youtube.com/topgunbarbershop"><span className={styles.yt}></span> YOUTUBE</a></div>
              </div>
            </div>
            
            {/* <div className="col-12 col-md-2">
              <div className={styles.links_f}>
                <div><a target="_blank" href="https://www.youtube.com/topgunbarbershop">YouTube</a></div>
                <div><a target="_blank" href="https://www.instagram.com/topgun.ru/">Instagram</a></div>
                <div><a target="_blank" href="https://vk.com/topgun">Вконтакте</a></div>
                <div><a target="_blank" href="https://t.me/topgun_ru">Telegram</a></div>
              </div>
            </div>
            <div className="col-12 col-md-1">
              <div className={styles.links_f} style={{flexDirection: "column"}}>
                <div style={{width: "100%", display: "flex", alignItems: "flex-end", flexDirection: "column"}}><a target="_blank" href="https://www.youtube.com/topgunbarbershop">ВК Видео</a></div>
                <div style={{width: "100%"}}><a target="_blank" href="https://www.instagram.com/topgun.ru/">Дзен</a></div>
              </div>
            </div> */}

            <div className="col-12">
              <div className={styles.copys}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className={styles.copys_links}>
                      <a target="_blank" href="https://topgun.ru/upload/personal-data-topgun.pdf"><FormattedMessage id="footer_user_policy" /></a>
                      <a target="_blank" href="/privacy"><FormattedMessage id="footer_policy" /></a>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={styles.copys_links_r}>
                      <div className={styles.copys_links_r_w}>
                        {/* <div className={styles.langs_wrap}>
                          <div className={styles.langs}>
                            <div className={store.locale == LOCALES.RUSSIAN ? styles.active : ""} onClick={() => setupLocale(LOCALES.RUSSIAN)}>RU</div>
                            <div className={store.locale == LOCALES.ENGLISH ? styles.active : ""} onClick={() => setupLocale(LOCALES.ENGLISH)}>EN</div>
                            <div className={store.locale == LOCALES.FRANCE ? styles.active : ""} onClick={() => setupLocale(LOCALES.FRANCE)}>FR</div>
                          </div>
                        </div> */}
                        <div className={styles.txt}><FormattedMessage id="footer_copys" /></div>
                        <div className={styles.txt}>© TOPGUN  2014 — 2024</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Footer)